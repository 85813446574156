import { FiChevronUp, FiKey, FiMaximize2, FiMenu, FiX } from "react-icons/fi";
import React, { Component, Fragment } from "react";

import Accordion01 from "../elements/Accordion";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Particles from 'react-particles-js';
import ScrollToTop from 'react-scroll-up';
import Scrollspy from 'react-scrollspy';
import TabOne from "../elements/tab/TabOne";

// import Slider from "react-slick";



const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Social Development Investment (SDI)',
        description: 'founded in 2017 as an NGO in Albania, bringing together a team of experts with more than 20 years experience in the development sector in Europe, Africa and Asia.',
    }
]


const ServiceList = [
    {
        icon: <FiMaximize2 />,
        title: 'Mission',
        description: 'Support sustainable livelihoods for local communities through socio-economic, financial, and technology inclusion.'
    },
    {
        icon: <FiKey />,
        title: 'Vision',
        description: 'Become a reference point in developing collaborative and socially responsible markets, using innovation and cross-sectoral expertise.'
    }
]


class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        // const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Social Development Investment (SDI)" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/sdilogo.svg" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'tabs','projects','testimonial']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Our Story</a></li>
                                    <li><a href="#service">Our Values</a></li>
                                    <li><a href="#about">How we work</a></li>
                                    <li><a href="#tabs">Our Services</a></li>
                                    <li><a href="#projects">Projects Portfolio</a></li>
                                    <li><a href="#testimonial">Collaborators</a></li>
                                    {/* <li><a href="#blog">Blog</a></li>
                                    <li><a href="#contact">Contact</a></li> */}
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 50
                                    },
                                    "size": {
                                        "value": 4
                                    }
                                },
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "repulse"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div style={{ backgroundImage: "url(/assets/images/bg/background-eli.png)"}}>
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h3 className="title display-4">{value.title}</h3> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    <div className="row mx-auto mt-5">
                                                        <div className="col-lg-6 col-12 my-auto">
                                                        <div className="content my-auto mb-0">
                                                            {/* <p className="subtitle">Mission & Vission</p> */}
                                                            <h4 className="fontWeight700">The organization contributes in better policy-making, programming, project implementation, monitoring and evaluation in the following areas of expertise</h4>
                                                            {/* <ul className="list-style mt--30"> */}
                                                        </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12 my-auto">
                                                        <ul className="list-inline mt-3 mb-0">
                                                                <li className="list-inline-item badge badge-pill badge-light">EU affairs</li>
                                                                <li className="list-inline-item badge badge-pill badge-light">Socio-Economic & Territorial Development (Regional, Local, Urban, Rural)</li>
                                                                <li className="list-inline-item badge badge-pill badge-light">Competitiveness & Innovation</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Tourism</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Employment and Skills</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Environment</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Value-Chain Development</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Micro-Finance, Micro-Insurance, Micro-marketing</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Multi-disciplinary support to SMEs</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Livelihood, Migration and Social Inclusion</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Legal Affairs</li>
                                                                <li className="list-inline-item badge badge-pill badge-light"> Health</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <p className="subtitle">We have developed a clear and in-depth under-standing of the expert guidance that is needed in the market, after working many years for public and private institutions, corporations, international organizations and local NGOs.</p>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>Our Values</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                            <React.Fragment>
                                    <div className="row">
                                        {ServiceContent.map( (val , i) => (
                                            <div className="col-sm-6 col-12">
                                                {/* <a href="/service-details"> */}
                                                    <div className="service service__style--2">
                                                        <div className="icon">
                                                            {val.icon}
                                                        </div>
                                                        <div className="content">
                                                            <h3 className="title">{val.title}</h3>
                                                            <p>{val.description}</p>
                                                        </div>
                                                    </div>
                                                {/* </a> */}
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                                {/* <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" /> */}
                            </div>
                        </div>
                            <div className="row mx-auto mt--30">
                            <div className="content mx-auto mt-5">
                                <ul className="list-style mt--30">
                                    <li>•	Our core values are strong ethics and integrity</li>
                                    <li>•	We believe that countries grow stronger by empowering their people and communities</li>
                                    <li>•	We believe that the best solutions are those that can be measured and are self-sustainable</li>
                                    <li>•	We believe in proactive and collaborative learning</li>
                                    <li>•	We believe in shared information and knowledge</li>
                                    <li>•   We believe we can unlock potential of every individual and local community we work with</li>
                                    <li>•	We believe in innovative economic and business models which free growth from consumption</li>
                                    <li>•	We believe that the best results are achieved through multisector, complementary and long-lasting partnerships</li>
                                </ul>
                            </div> 
                            </div>
                    </div>
                </div>  
                {/* End Service Area  */} 

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                {/* <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">How we work</h2>
                                            <p className="description"> <span class="badge badge-pill badge-secondary">  •	We bring a strong conceptual and analytical approach into our everyday work • </span> • <span class="badge badge-pill badge-secondary">  •	We bring excellent interpersonal, communication and reporting skills • </span>  • <span class="badge badge-pill badge-secondary">  • We bring flexibility and pragmatism • </span> • <span class="badge badge-pill badge-secondary">  •	We bring tailor-made and systemic solutions • </span>	<span class="badge badge-pill badge-secondary">  • We bring tailor-made and systemic solutions • </span>  • <span class="badge badge-pill badge-secondary">  • We bring innovative and updated models, methodologies and tools, aligned with local and global standards • </span> • <span class="badge badge-pill badge-secondary">  • We bring education and awareness about social and economic empowerment • </span> •	<span class="badge badge-pill badge-secondary">  • We co-design services with users • </span> • <span class="badge badge-pill badge-secondary">  • We serve as a connection hub between target populations and funding institutions • </span> • <span class="badge badge-pill badge-secondary">  • We translate ideas into concrete projects • </span> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--120  bg_color--1" id="tabs">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Our Services</h2>
                                            {/*<p className="description">{description}</p> */}
                                        </div>
                                        <div className="row mt--30">
                                            <TabOne tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5" id="projects">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Projects Portfolio</h2>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start Contact Us */}
                {/* <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div> */}
                {/* End Contact Us */}


                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120" id="testimonial">
                    <div className="container">
                        <div className="row">
                        <div className="section-title">
                        </div>
                            <div className="col-lg-8 mx-auto">
                                <h2 className="text-center">Partners</h2>
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;