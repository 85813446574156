import React, { Component } from "react";

import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || reference point in developing collaborative and socially responsible markets </title>
                    <meta name="description" content="with more than 20 years’ experience in the development sector in Europe, Africa and Asia." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
