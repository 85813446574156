import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { FiCheck } from "react-icons/fi";

class TabsOne extends Component{
    render(){
        let 
        tab1 = "Social Innovation and Entrepreneurship", 
        tab2 = "Analysis, Advice & Research",
        tab3 = "Project Management",
        tab4 = "Training and Coaching";
        const { tabStyle } = this.props;
        var namesItemOne = [
            'Develop new ideas, services and models to better address social issues',
            'Address societal challenges effectively and efficiently within a tight budget',
            'Support strategic social investment',
            'Lifelong learning to ensure adequate livelihoods',
        ];
        var namesItemTwo = [
            'Advice in policymaking and good governance in our areas of expertise',
            'Qualitative and Quantitative Research in support to a stronger evidence-based policy and decision-making',
            'Participation, and cooperation in the preparation of draft laws and other normative acts in the sectors of expertise',
            'Publication and reproduction of methodical, educational and scientific materials',
        ];
        var namesItemThree = [
            'Initiating, planning, executing, controlling, and closing projects',
            'Manage teams to achieve specific goals and meet specific success criteria',
            'Reach all of the project goals within the given constraints',
        ];
        var namesItemFour = [
            'A comprehensive needs analysis to achieve the specific results you require.',
            'Training based on evidence-based best practice approaches.',
            'Training tailored to your sector, participants’ roles and needs.',
            'Alignment with organizational goals, plans and strategy.',
            'Training which is practical, engaging and highly interactive.',
            'Highly Qualified CEFE Licensed Trainers, adapting the most modern and experiential methodologies to your sector and context.',
        ];
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>This SDI niche area of operation, invites individuals, public institutions, private operators, civil society, media and academia, to:</p>

                                           <div className="mt--30">
                                               {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                               <ul className="list-style--1">
                                                   {namesItemOne.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                                   <li><FiCheck />Design and implement innovative and complementary mechanisms including:
                                                   <ul className="list-group px-3">
                                                       <li className="list-group-item pt-1 px-0 pb-0 mb-0 border-0">1. micro-finance, micro-insurance, micro marketing</li>
                                                       <li className="list-group-item p-0 mb-0 border-0">2. value chain development</li>
                                                       <li className="list-group-item p-0 mb-0 border-0">3. public private partnerships</li>
                                                   </ul>
                                                   </li>
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                       <div className="mt--30">
                                               {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                               <ul className="list-style--1">
                                                   {namesItemTwo.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Project management success requires discipline at both the individual at organizational levels. As project management experts, we understand how hard it can be to change behaviors. We will work with you to solidify adoption of your process and build the discipline that will guarantee your long -term success. With a varied background in project implementation, we provide temporary project management resources to meet the needs for any contract length. Through our experienced project managers, we support project teams on:</p>
                                           <div className="mt--30">
                                               {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                               <ul className="list-style--1">
                                                   {namesItemThree.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                           

                                       </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We deliver customized training and coaching services across a diverse range of NGOs, government public officials, private sector operators and young entrepreneurs. We deliver training across Albania including urban, sub-urban, rural, mountainous and peripheral remote areas of the country; We offer for our counterparts the following:</p>
                                           <div className="mt--30">
                                               {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                               <ul className="list-style--1">
                                                   {namesItemFour.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>

                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsOne;