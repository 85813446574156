import 'react-accessible-accordion/dist/fancy-example.css';

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import React, { Component } from "react";

// Demo styles, see 'Styles' section below for some notes on use.



class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Youth for Environment Kukës 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Youth for Environment Kukës <strong>(YEK)</strong> . aims to create a collaboration between public institution municipality and the youth of the city by engaging the youth on data collection system on environmental issues, which can and upcycling activities that can reduce the damages of the current situation. And as well training at least 5 business how to upcycle the residue that is created in their business into  eco- friendly products that can help develop a small scale circular economy in the city (specifically upcycling used cooking oil from restaurants and fast food shops and used engine oil from mechanic workshops which is currently  thrown away ending up on the lake of Kukes damaging the environment into eco-friendly products as detergents and rust moist solutions).</p>
                        <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> November 2020 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> April 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EU/ UNDP</h6></li>
                            </ul>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Technical Assistance.
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p><strong>Technical Assistance</strong> Assessment and technical assistance to SME in production sector specifically in design and implementation of the project <strong>"Business development strategy and export plan" </strong> . </p>
                        <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> March -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> May 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EBRD</h6></li>
                            </ul>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Covid-19 Support
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>Covid-19 Support</strong> In the frame of <strong>ReLoaD Program SDI</strong>  is implementing a project to support kindergartens and senior citizens in the municipality of Elbasan as an action to Covid19 pandemic situation. </p>

                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> August -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> September 2020 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EU/ UNDP/ Elbasan municipality</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        MATH Modern Albanian Tradition Hub
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>MATH Modern Albanian Tradition Hub</strong> Financed by <strong> GIZ Albania </strong> in the frame of <strong>Sustainable Economic and regional development, employment promotion and Vocational Education and Training – ProSEED </strong>, SDI is implementing the project MATH Modern Albanian Tradition Hub in Kukes, reactivating the value chain of wool in the region training 100 people to be active in this value chain. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> October 2019 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> November 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> GIZ Albania</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Via Dinarica Scout Movement. 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>Establishment of the <strong> Scouts of Via Dinarica </strong> is supported through a grant provided by the <strong> Regional Cooperation Council’s Tourism Development and Promotion Project </strong>. The Project is funded by the <strong> European Union  and implemented by the RCC </strong> in an effort to contribute to the growth and competitiveness of the six Western Balkans economies by supporting development and promotion of joint regional cultural and adventure tourism offer.
                    </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> October 2019 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> November 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6>EU / RCC</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Technical Assistance
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>Assessment and technical assistance to SME in tourism sector specially in market development, marketing and sales. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> January -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> April 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EBRD</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Market Development of Artisanal Wool in Kukës 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>MDAWK</strong> As a project is creating access and developing a national and foreign market for wool artisans by standardizing their products, linking the stakeholders of the wool value chains through trainings (on product development and presentation, and soft business skills), workshops and marketing activities to meet the requirements and demands of the modern and foreign market. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> April -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> December 2019 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> Risi Albania/ Helvetas</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Identification of improvements 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>Identification of improvements</strong> of the course for the <strong> Via Dinarica </strong> was the project regarding trail/track route section in Albania. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> May -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> July 2019 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> Albanian Development Fund</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Organic Soap Roskovec II
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>SOR II</strong> is the extension of the <strong> Sapune Organic Roskovec (SOR) I</strong> a women empowerment project aimed at enhancing <strong> economic activities and job creation </strong> by developing the new product based on olive oil (shampoo and liquid soap). </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> June 2019 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> February 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EU /UNDP /Roskovec Municipality</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Katunar
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>Is a spinoff project/brand from all SDI projects to create a sustainability for projects after intervention by building <strong>a brand that will help sell and market products from projects that have been implemented in Albania and abroad?</strong> </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> December 2019 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> August 2020 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EU/CCP/RDA</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Technical Assistance and evaluation
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>Evaluation and monitoring of <strong> Margarita Artisanal Centre</strong> in Berat. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> September 2018 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> April 2019 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> Risi Albania/ Helvetas</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Organic Soap Roskovec I
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>As a project created new economic activities for the community of Roskovec, especially for women and young people through vocational education of soap production from unsold olive oil, hence, creating employment and increasing households’ income. The <strong> SOR I </strong> trained a group of <strong> 20 women, resident in the Municipality of Roskovec </strong> to produce and sell organic soap from olive oil. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> September 2018 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> April 2019 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> EU/UNDP/ The Municipality of Roskovec</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Modern Albanian Tradition
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p><strong>MAT I</strong> Was the project for empowering women through economic activities in the wool artisanal sectors in Tirana. More specifically, <strong> 25 women have been trained on modern artisanal products and soft business skills </strong>. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> January -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> October 2018 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> GIZ Albania</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Tracer study for VET graduates
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>The purpose of the project is to support the administration and design of a survey for vocational graduates. These vocational training is provided by <strong> GIZ </strong> for providing courses in various fields helping the beneficiaries find a job. </p>
                    <div className="row">
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Duration (from – to) </span></li>
                                <li class="list-inline-item">
                                <p className="h6"> April 2017 -</p></li>
                                <li class="list-inline-item">
                                <p className="h6"> December 2021 </p></li>
                            </ul>
                            </div>
                            <div className="col-6">
                            <ul class="list-inline">
                                <li class="list-inline-item"><span>Organisation that funded project </span></li>
                                <li class="list-inline-item">
                                <h6> GIZ Albania</h6></li>
                            </ul>
                            </div>
                    </div> 
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        )
    }
}

export default Accordion01;





